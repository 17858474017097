/**
 * @file Waitlist Survey
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import isMobilePhone from 'validator/lib/isMobilePhone'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import ReactDatePicker from 'react-datepicker'
import { PureInput } from '../components/input'
import Layout from '../components/layout'
import Button from '../components/button'
import useQueryString from '../hooks/useQueryString'
import {
  WAITLIST_REF_CHECK_URL,
  WAITLIST_SUBMIT_SURVEY_URL,
} from '../constants'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  input {
    background-color: #f7f7fa;
  }
`

const Form = styled.form`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  max-width: 315px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  h3 {
    margin-bottom: 7px;
  }

  > * {
    margin-bottom: 25px;
  }
`

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;

  h2 {
    font-size: 2.3em;
    margin-bottom: 15px;
  }

  a {
    text-decoration: none;
    color: #dd3f3e;
  }
`

const Survey = () => {
  const { register, control, formState, handleSubmit } = useForm({
    mode: 'onChange',
  })
  const { ref } = useQueryString()
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (ref) {
      ;(async () => {
        const { isValid } = await fetch(WAITLIST_REF_CHECK_URL, {
          method: 'POST',
          body: JSON.stringify({ ref }),
          headers: { 'Content-Type': 'application/json' },
        }).then(resp => resp.json())

        if (!isValid) navigate('/survey', { replace: true })
        setLoading(false)
      })()
    } else {
      setLoading(false)
    }
  }, [ref])

  const onSubmit = data => {
    setLoading(true)
    ;(async () => {
      const { success } = await fetch(WAITLIST_SUBMIT_SURVEY_URL, {
        method: 'POST',
        body: JSON.stringify({ ...data, ref }),
        headers: { 'Content-Type': 'application/json' },
      }).then(resp => resp.json())
      setSubmitted(success)
      setLoading(false)
    })()
  }

  if (loading)
    return (
      <Layout>
        <ThankYouContainer>
          <h3>Loading...</h3>
        </ThankYouContainer>
      </Layout>
    )

  return (
    <Layout>
      <Container>
        {!submitted ? (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Tell us About You</h2>
            <h3>Name</h3>
            <PureInput
              name="name"
              placeholder="Elaine Cheng"
              ref={register({ required: true })}
            />
            <h3>Email</h3>
            <PureInput
              name="email"
              placeholder="solo@soloclub.app"
              ref={register({ required: true })}
            />
            {!ref && (
              <div>
                <h3>Phone Number</h3>
                <Controller
                  as={PhoneInput}
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: true,
                    validate: value =>
                      isMobilePhone(value) || 'Invalid Phone Number',
                  }}
                  defaultValue=""
                  defaultCountry="US"
                  inputComponent={PureInput}
                  inputClassName="survey-phone-input"
                  placeholder="Phone Number"
                  type="tel"
                  autoComplete="tel"
                />
              </div>
            )}
            <h3>Where did you live before Covid?</h3>
            <PureInput
              name="previousLocation"
              placeholder="New York City"
              ref={register({ required: true })}
            />
            <h3>Where do you live now?</h3>
            <PureInput
              name="location"
              placeholder="Los Angeles"
              ref={register({ required: true })}
            />
            <h3>What were you up to before Covid?</h3>
            <PureInput
              name="previousProfession"
              placeholder="Student"
              ref={register({ required: true })}
            />
            <h3>What is your occupation now?</h3>
            <PureInput
              name="profession"
              placeholder="Content Creator"
              ref={register({ required: true })}
            />
            <h3>Date of Birth</h3>
            <Controller
              control={control}
              name="birthDate"
              rules={{
                required: true,
                validate: value => dayjs(value).isBefore(dayjs()),
              }}
              defaultValue=""
              render={props => (
                <ReactDatePicker
                  {...props}
                  placeholderText="12 December 1990"
                  selected={props.value} // eslint-disable-line react/prop-types
                  customInput={<PureInput />}
                  dateFormat="dd MMMM yyyy"
                />
              )}
            />
            <h3>Paste Your Socials here</h3>
            <PureInput
              name="socials"
              placeholder="Instagram, fb, twitter, etc"
              ref={register({ required: true })}
            />
            <Button
              disabled={!formState.isValid}
              style={{ marginBottom: '40px', zIndex: 0 }}
            >
              Submit
            </Button>
          </Form>
        ) : (
          <ThankYouContainer>
            <h2>Thank You 🥳</h2>
            <h5>
              Got Questions?{' '}
              <a href="mailto:info@soloclub.app">info@soloclub.app</a>
            </h5>
          </ThankYouContainer>
        )}
      </Container>
    </Layout>
  )
}

export default Survey
